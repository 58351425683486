@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@font-face {
  font-family: 'parchment';
  src: local('CustomFont1Name'),
  url("/static/media/ParchmentMf-Vqge 2.9a3e0b1f.woff2") format('woff2');
}

html {
  height: 100%;
  max-width: 100%;
  font-size: 100%;

  -webkit-overflow-scrolling: touch;
}

body {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 100%;
  max-width: 100%;
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Ubuntu", "Helvetica Neue", sans-serif;
  line-height: 1;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(42, 42, 46);
}

body.noscroll {
  overflow: hidden;
}

#root {
  display: flex;
  flex: 0 0 100%;
}

#root > * {
  flex: 0 0 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, monospace;
}

div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: inherit;
}

button,
input {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  touch-action: manipulation;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal;
}

strong {
  font-weight: bold;
}

u {
  text-decoration: underline;
}

i {
  font-style: italic;
}

:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

body {
  background: rgb(211,209,230);
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

